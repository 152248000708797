import { covertUtcToRestaurantDateTime, commafy, getCurrencyWithAmount, shouldCommafy } from '@/common/utility';
import { Section } from '@/components/common';
import { onPushEvent } from '@/services/lib/gtm';
import { getCurrencyPrecision } from '@/services/utils/k_currency';
import { Grid, Box, Tabs, Tab } from '@mui/material';
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Tooltip, CartesianGrid, Area } from 'recharts';
import { useTranslation } from '@/hooks/translations';

export default function RestaurantChart({
    chartMode,
    chartData,
    handleTabChange,
    showTipCard,
    lang,
}: {
    chartMode: string;
    chartData: any;
    handleTabChange: any;
    showTipCard: boolean;
    lang?: string;
}) {
    const { t } = useTranslation('common');

    const withCommaSeparator = shouldCommafy(chartData?.currencyCode);

    return (
        <Grid item xs={12}>
            <Section
                sx={{ borderColor: '#E5E5E5', borderWidth: '1px', borderRadius: '20px' }}
                onMouseEnter={() => {
                    onPushEvent('User_hover_on_the_revenue_chart_to_see_the_number');
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            sm: 'row',
                            xs: 'column',
                        },
                        justifyContent: 'space-between',
                    }}
                >
                    <Tabs value={chartMode} onChange={handleTabChange} variant="fullWidth">
                        <Tab value="revenue" label={t('Revenue')} />
                        {showTipCard && <Tab value="tip" label={t('Tips')} />}
                    </Tabs>
                </Box>
                <ResponsiveContainer width="99%" height={300}>
                    {chartData && (
                        <AreaChart
                            data={chartData.revenueChart.map((d: any) => ({
                                date: covertUtcToRestaurantDateTime(d.date, lang === 'ja' ? 'MMM DD[日]' : 'DD MMM'),
                                revenue:
                                    chartMode === 'tip'
                                        ? (parseFloat(d.paidAmountWithTip) - parseFloat(d.paid)).toFixed(
                                              getCurrencyPrecision(chartData.currencyCode),
                                          )
                                        : parseFloat(d.paid).toFixed(getCurrencyPrecision(chartData.currencyCode)),
                            }))}
                            margin={{
                                top: 40,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <defs>
                                <linearGradient id="colorRev" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="00%" stopColor="#7D00D4" />
                                    <stop offset="25%" stopColor="#7D00D4" />
                                    <stop offset="100%" stopColor="#C4C4C4" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                tick={{
                                    fontSize: '14px',
                                }}
                            />
                            <YAxis
                                dataKey={(v) => parseInt(v.revenue, 10)}
                                tick={{
                                    fontSize: '14px',
                                }}
                                axisLine={false}
                                // change the showing of the y-axis if value is 60000 lets says show as 60,000
                                tickFormatter={(value) => {
                                    if (withCommaSeparator) {
                                        const cp = getCurrencyPrecision(chartData.currencyCode);
                                        return `${commafy(value, cp)}`;
                                    }
                                    return value;
                                }}
                                width={withCommaSeparator ? 70 : undefined}
                            />
                            <Tooltip
                                formatter={(value: any) => [
                                    `${getCurrencyWithAmount(
                                        value,
                                        chartData.currencySymbol,
                                        chartData.currencyCode,
                                        true,
                                    )}`,
                                    '',
                                ]}
                                separator=""
                                cursor={{ stroke: '#000000', strokeWidth: 1, strokeDasharray: '10 5' }}
                                contentStyle={{
                                    backgroundColor: '#FAFAFABF',
                                    borderRadius: '10px',
                                    borderWidth: '0px',
                                    minWidth: '120px',
                                    backdropFilter: 'blur(5px)',
                                    color: '#000000',
                                }}
                                itemStyle={{ color: '#000000' }}
                            />
                            <CartesianGrid stroke="#E5E5E5" strokeDasharray="0" vertical={false} />
                            <Area
                                type="monotone"
                                dataKey="revenue"
                                stroke="#7D00D4"
                                strokeWidth={2}
                                fillOpacity={1}
                                fill="url(#colorRev)"
                                activeDot={{
                                    stroke: '#0075FF',
                                    fill: '#0075FF',
                                }}
                            />
                        </AreaChart>
                    )}
                </ResponsiveContainer>
            </Section>
        </Grid>
    );
}
